import { render, staticRenderFns } from "./TagList.vue?vue&type=template&id=463da533&scoped=true&"
import script from "./TagList.vue?vue&type=script&lang=js&"
export * from "./TagList.vue?vue&type=script&lang=js&"
import style0 from "./TagList.vue?vue&type=style&index=0&id=463da533&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "463da533",
  null
  
)

export default component.exports