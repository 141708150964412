var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"row page-title-header"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"page-header"},[_c('b-breadcrumb',{staticClass:"m-0"},[_c('b-breadcrumb-item',{attrs:{"to":{
              name: 'DashboardHome',
            }}},[_c('i',{staticClass:"fa fa-home"})]),_c('b-breadcrumb-item',{attrs:{"to":{
              name: 'TagsList',
            }}},[_vm._v("標籤管理 ")]),_c('b-breadcrumb-item',{attrs:{"active":""}},[_vm._v("標籤列表")])],1)],1)])]),_c('b-card',[_c('div',{staticClass:"row d-flex mb-4 mb-xl-2 justify-content-between"},[_c('h4',{staticClass:"col-12 col-xl-2 mb-2 font-weight-bold"},[_vm._v("標籤列表")]),_c('div',{staticClass:"\n            col-12 col-xl-6\n            d-flex\n            flex-column flex-xl-row\n            align-items-start align-items-xl-center\n          "},[(_vm.checkPermission([_vm.consts.TAG_LIST_CREATE]))?_c('b-button',{staticClass:"flex-shrink-0 mb-2 mb-xl-0 mr-3",attrs:{"variant":"primary","to":{ name: 'TagCreate' }}},[_c('i',{staticClass:"fa fa-plus"}),_vm._v("新增標籤")]):_vm._e(),_c('b-input-group',{staticClass:"mb-2"},[_c('b-form-input',{attrs:{"placeholder":"搜尋"},model:{value:(_vm.keyword),callback:function ($$v) {_vm.keyword=$$v},expression:"keyword"}}),_c('b-input-group-append',[_c('b-button',{on:{"click":_vm.getTags}},[_c('i',{staticClass:"fa fa-search"})])],1)],1)],1)]),_c('b-card-text',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('b-overlay',{attrs:{"show":_vm.showLoading,"rounded":"sm"}},[_c('b-table',{attrs:{"striped":"","hover":"","responsive":"","items":_vm.keywords,"fields":_vm.fields},scopedSlots:_vm._u([{key:"cell(actions)",fn:function(data){return [_c('b-button',{attrs:{"variant":"inverse-primary","to":{
                    name: 'TagView',
                    params: { tag_id: data.item.id },
                  }}},[_vm._v(" 查看 ")]),_c('b-button',{attrs:{"variant":"inverse-warning","to":{
                    name: 'TagEdit',
                    params: { tag_id: data.item.id },
                  }}},[_vm._v(" 編輯 ")]),_c('b-button',{attrs:{"variant":"inverse-danger"},on:{"click":function($event){return _vm.deleteTags(data.item)}}},[_vm._v(" 刪除 ")])]}}])})],1)],1)]),_c('div',{staticClass:"d-flex justify-content-center",staticStyle:{"margin-top":"80px"}},[_c('b-pagination',{attrs:{"total-rows":_vm.totalRows,"per-page":_vm.perPage,"align":"center"},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }